import React, { useState } from 'react';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { useSpring, animated } from '@react-spring/web';

const Accordion = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const animationStyles = useSpring({
    maxHeight: isOpen ? '5500px' : '0px', // Ограничиваем высоту
    opacity: isOpen ? 1 : 0,
    config: { duration: 600 }, // Длительность анимации
  });

  const toggleAccordion = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div style={{ paddingBottom: '15px' }}>
      <div
        style={{
          padding: '20px 24px 4px 22px',
          cursor: 'pointer',
          backgroundColor: isOpen ? 'var(--tgui--surface_primary)' : 'var(--tgui--surface_primary)',
          display: 'flex',
          justifyContent: 'space-between',
        }}
        onClick={toggleAccordion}
      >
        <span>{title}</span>
        <span>{isOpen ? <FaArrowUp size='20' /> : <FaArrowDown size='20' />}</span>
      </div>
      <animated.div
        style={{
          ...animationStyles,
          overflow: 'hidden',
        }}
      >
        <div style={{ padding: '10px' }}>{children}</div>
      </animated.div>
    </div>
  );
};

export default Accordion;
