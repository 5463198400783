import React, { useEffect, useState, useCallback } from "react";
import { Cell, Section, List, Button, Banner, IconContainer, Modal, Text, Divider, Select, Slider, Input, Chip } from '@telegram-apps/telegram-ui';
import '@telegram-apps/telegram-ui/dist/styles.css';
import { FaCircleInfo, FaGears, FaStar } from 'react-icons/fa6';
import { postEvent, subscribe, unsubscribe } from '@telegram-apps/sdk';
import { fetchBackend, analyticsAdd } from '../api';
import { useTranslation } from 'react-i18next';
import Accordion from './misc/Accordion.js';

// Компонент модального окна
const AccountSettingsModal = React.memo(({ userData, open, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal
      style={{zIndex: '9991'}}
      open={open}
      onOpenChange={(isOpen) => {
        if (!isOpen) onClose(); // Закрываем модалку только при необходимости
      }}
      header={<Modal.Header>{t('accountsettings')}</Modal.Header>}
    >
      <div style={{ height: '80vh' }}>
        <Cell multiline='true'>
          <Text weight="3">
            {t('accountsettings')}
            <br/>
            ChatID - {userData.chat_id}
          </Text>
        </Cell>
      </div>
    </Modal>
  );
});

// Компонент модального окна
const AboutModal = React.memo(({ open, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal
      style={{zIndex: '9991'}}
      open={open}
      onOpenChange={(isOpen) => {
        if (!isOpen) onClose(); // Закрываем модалку только при необходимости
      }}
      header={<Modal.Header>{t('aboutapptitle')}</Modal.Header>}
    >
      <div style={{ height: '80vh' }}>
        <Cell multiline='true'>
          <Text weight="3">
            <div dangerouslySetInnerHTML={{ __html: t('aboutapp').split('\n').join('<br/>') }}></div>
          </Text>
        </Cell>
      </div>
    </Modal>
  );
});

const BuyModal = React.memo(({ open, onClose, tariffSelected, tariffArr, buySubscription }) => {
  const { t } = useTranslation();
  const [ totalPrice, setTotalPrice ] = useState();
  const [ selectedDuration, setSelectedDuration ] = useState('3');

  useEffect(() => {
    tariffArr.map(([product, tariffs]) => {
        tariffs.map((tt, i) => {
          if(tt.tariff_id === tariffSelected){
            setTotalPrice(tt.price*selectedDuration)
          }
      })
    })

  }, [tariffSelected, selectedDuration])

  const handleChangeDuration = (event) => {
    setSelectedDuration(event.target.value); // Обновляем состояние
  };

  return (
    <Modal
      style={{zIndex: '9991'}}
      open={open}
      onOpenChange={(isOpen) => {
        if (!isOpen) onClose(); // Закрываем модалку только при необходимости
      }}
      header={<Modal.Header>{t('subscribe')}</Modal.Header>}
    >
      <div>
        <Cell multiline='true'>
          <Text>  {t('selectedtariff')}: {t('tariffs.' + tariffSelected + '.name')}</Text>
          <Select style={{width: '100vw'}} defaultValue={selectedDuration} onChange={handleChangeDuration} header={t('duration')}>
            <option value="1">{t('month')}</option>
            <option value="3">{t('threemonth')}</option>
            <option value="6">{t('halfyear')}</option>
            <option value="12">{t('year')}</option>
          </Select>
          <Text>  {t('total')} {totalPrice} <FaStar style={{ color: 'yellow' }} size={15} /> {t('per')} {t(selectedDuration+' months')}</Text>
          <Button onClick={() => buySubscription(tariffSelected, selectedDuration)} style={{width: '100vw'}} mode="filled" size="l">
            {t('subscribe')}
          </Button>
        </Cell>
        <br/>
        <br/>
      </div>
    </Modal>
  );
});

const TopUpModal = React.memo(({ open, onClose, topUp }) => {
  const { t } = useTranslation();
  const [ amount, setAmount ] = useState('500');

  const setAmountHandler = (val) => {
    setAmount(val); // Обновляем состояние
  };

  return (
    <Modal
      style={{zIndex: '9991'}}
      open={open}
      onOpenChange={(isOpen) => {
        if (!isOpen) onClose(); // Закрываем модалку только при необходимости
      }}
      header={<Modal.Header>{t('topup')}</Modal.Header>}
    >
      <div>
        <Section style={{padding: '25px;'}}>
          <Input header={t('amount')} value={amount} placeholder="100" />
          <Slider step={100} min={100} max={100000} defaultValue={500} onChange={setAmountHandler} />
          <Cell>
            <Button onClick={() => topUp(amount)} style={{width: '100vw'}} mode="filled" size="l">
              {t('topup')} {t('for')} {amount} <FaStar style={{ color: 'yellow' }} size={20} />
            </Button>
          </Cell>
        </Section>
        <br/>
        <br/>
      </div>
    </Modal>
  );
});

const SettingsScreen = ({ userData, initDataRaw, initData }) => {
  const [parsedPT, setParsedPT] = useState({});
  const { t } = useTranslation();
  const [ modals, setModals ] = useState({}); // Состояние для всех модалок
  const [ tariffSelected, setTariffselected] = useState(0);

  // Слушатель событий для покупки подписки
  useEffect(() => {
    const MiniAppsSubscribeListener = (event) => {
      if (event[0] === 'invoice_closed') {
        if (event[1].status === 'paid') {
          analyticsAdd('Bought subscription', initDataRaw);
          postEvent('web_app_open_popup', {
            title: t('hooray'),
            message: t('subscriptionpayed'),
            buttons: [{ id: 'ok', type: 'ok', text: 'ok' }],
          });
          window.location.reload();
        } else if (event[1].status === 'cancelled') {
          analyticsAdd('Declined subscription', initDataRaw);
          postEvent('web_app_open_popup', {
            title: t('well'),
            message: t('nothenno'),
            buttons: [{ id: 'ok', type: 'ok', text: 'ok' }],
          });
        }
      }
    };
    subscribe(MiniAppsSubscribeListener);
    return () => unsubscribe(MiniAppsSubscribeListener);
  }, [initDataRaw, t]);

  // Разбор тарифов
  useEffect(() => {
    let parsedProductsTariffs = { ...userData.allProductsWithTariffs };
    userData.userTariffs.forEach((item) => {
      const tariffs = parsedProductsTariffs[item.product_name];
      if (tariffs) {
        const tariff = tariffs.find((t) => t.tariff_id === item.tariff_id);
        if (tariff) {
          tariff.his = true;
          if(tariff.price === 0){
            tariff.quantity_left = item.quantity_left;
          }
          tariff.payed_until = item.payed_until;
        }
      }
    });
    setParsedPT(parsedProductsTariffs);
  }, [userData]);

  // Форматирование даты
  const formatSubscriptionDate = (dateString) => {
    const date = new Date(dateString);
    return t('payeduntil') + date.toLocaleDateString(initData.languageCode, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  // Покупка подписки
  const buySubscription = (id, duration) => {
    fetchBackend(initDataRaw, '/createInvoice', 'POST', { tariff: id, duration: duration }).then((r) => {
      if(r.link === 'boughtfrombalance'){
        analyticsAdd('Bought subscription from balance', initDataRaw);
        postEvent('web_app_open_popup', {
          title: t('hooray'),
          message: t('subscriptionpayed'),
          buttons: [{ id: 'ok', type: 'ok', text: 'ok' }],
        });
        window.location.reload();
      }else{
        postEvent('web_app_open_invoice', { slug: r.link.split('$')[1] });
      }
    });
  };

  // Покупка подписки
  const topUp = (amount) => {
    fetchBackend(initDataRaw, '/createInvoiceTopUp', 'POST', { amount: amount }).then((r) => {
      analyticsAdd('Balance top up for '+amount, initDataRaw);
      postEvent('web_app_open_invoice', { slug: r.link.split('$')[1] });
    });
  };

  // Открытие/закрытие модалки
  const openModal = useCallback((modalName) => {
    setModals((prev) => ({ ...prev, [modalName]: true }));
  }, []);

  const closeModal = useCallback((modalName) => {
    setModals((prev) => ({ ...prev, [modalName]: false }));
  }, []);

  return (
    <>
      <List>
        <Section key='settings' header={t('mainsettingsheader')}>
          <Cell onClick={() => openModal('About')} before={<IconContainer><FaCircleInfo /></IconContainer>}>
            {t('aboutapptitle')}
          </Cell>
          <Cell onClick={() => openModal('AccountSettings')} before={<IconContainer><FaGears /></IconContainer>}>
            {t('accountsettings')}
          </Cell>
          {/*<Cell disabled before={<IconContainer><FaCircleInfo /></IconContainer>}>
            {t('soon')}
          </Cell>*/}
        </Section>
        <Section key='balance' header={t('balance')} footer={t('whatisthebalance')}>
          <Cell
          before={
            <>{userData.balance} <FaStar style={{ color: 'yellow' }} size={20} /></>
          }
          after={
            <Button size='s' onClick={() => openModal('TopUp')}>{t('topup')}</Button>
          }>
          </Cell>
          <br/>
        </Section>
        <style>
          {`
            hr {
              display: none;
            }
            section {
              box-shadow: none !important;
            }
          `}
        </style>
        {Object.entries(parsedPT).map(([product, tariffs]) => (
          <React.Fragment key={product}>
            <Section header={
              <div style={{textAlign: 'center', justifyContent: 'center'}}>
                <Modal
                  style={{zIndex:'9991'}}
                  header={<Modal.Header>{t(product)}</Modal.Header>}
                  trigger={
                    <Button style={{width: '90vw'}} mode='bezeled' size='m'>{t(product)}</Button>
                  }>
                  <div className='noshadow' style={{ height: '70vh' }}>
                    <Section style={{padding: '25px'}}>
                      <div dangerouslySetInnerHTML={{ __html: t(product)+' - '+t(product + '_description').split('\n').join('<br/>') }}></div>
                    </Section>
                  </div>
                </Modal>
              </div>
            }>
              {tariffs.map((tt, i) => (
                tt.his?<Banner
                  key={`${i}*${tt.tariff_id}`}
                  callout={
                    <>
                      {tt.price} <FaStar style={{ color: 'yellow' }} size={15} />/{t('month')} {tt.payed_until ? formatSubscriptionDate(tt.payed_until) : ''}
                    </>
                  }
                  description={
                    <>
                      {t('tariffs.' + tt.tariff_id + '.littleDescription')}
                      {tt.price === 0 && (
                        <>
                          <br />
                          {t('quantity_left')}: {tt.quantity_left}
                        </>
                      )}
                    </>
                  }
                  header={t('tariffs.' + tt.tariff_id + '.name')}
                  type="section"
                >
                  <Button disabled mode="bezeled" size="s">
                    {t('current')}
                  </Button>
                  <Button onClick={() => alert(t('tariffs.' + tt.tariff_id + '.bigDescription'))} mode="outline" size="s">
                    {t('moreinfo')}
                  </Button>
                </Banner>:<></>
              ))}
              <Accordion title={t('alltarifs')}>
                {tariffs.map((tt, i) => (
                  <Banner
                    key={`${i}*${tt.tariff_id}`}
                    callout={
                      <>
                        {tt.price} <FaStar style={{ color: 'yellow' }} size={15} />/{t('month')} {tt.payed_until ? formatSubscriptionDate(tt.payed_until) : ''}
                      </>
                    }
                    description={t('tariffs.' + tt.tariff_id + '.littleDescription')}
                    header={t('tariffs.' + tt.tariff_id + '.name')}
                    type="section"
                  >
                    {!tt.his ? (
                      <Button
                        onClick={
                          () => {
                            if(tt.price === 0){
                              alert(t('freetariffwillstartauto'))
                            }else{
                              setTariffselected(tt.tariff_id);
                              openModal('Buy');
                            }
                          }
                        }
                        mode="filled" size="s">
                          {t('subscribe')}
                      </Button>
                    ) : (
                      <Button disabled mode="bezeled" size="s">
                        {t('current')}
                      </Button>
                    )}
                    <Button onClick={() => alert(t('tariffs.' + tt.tariff_id + '.bigDescription'))} mode="outline" size="s">
                      {t('moreinfo')}
                    </Button>
                  </Banner>
                ))}
              </Accordion>
            </Section>
          </React.Fragment>
        ))}
      </List>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>

      {/* Рендер модалки */}
      <AccountSettingsModal
        userData={userData}
        open={modals.AccountSettings || false}
        onClose={() => closeModal('AccountSettings')}
      />
      <AboutModal
        open={modals.About || false}
        onClose={() => closeModal('About')}
      />
      <BuyModal
        open={modals.Buy || false}
        onClose={() => closeModal('Buy')}
        tariffSelected={tariffSelected}
        tariffArr={Object.entries(parsedPT)}
        buySubscription={buySubscription}
      />
      <TopUpModal
        open={modals.TopUp || false}
        onClose={() => closeModal('TopUp')}
        topUp={topUp}
      />
    </>
  );
};

export default SettingsScreen;
