export const backendurl = 'https://'+process.env.REACT_APP_DOMAIN+'/api'

// main fetch backend function, get/post
export async function fetchBackend(initDataRaw, path, method='GET', body=null) {
  try {
    const params = method === 'POST'?{
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'x-init-data': initDataRaw, // Передаём initDataRaw в заголовках
      },
      body: JSON.stringify(body)
    }:{
      method: method,
      headers: {
        'x-init-data': initDataRaw, // Передаём initDataRaw в заголовках
      },
    };
    const response = await fetch(backendurl + path, params);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status} - ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Ошибка при запросе:', error.message);
    throw error; // Пробрасываем ошибку для обработки на уровне вызова
  }
}

// main analytic sender through backend, coz TG mini apps decline requests onto another domains, it is like proxy
export async function analyticsAdd(eventName, initDataRaw){
  fetchBackend(initDataRaw, '/capture', 'POST', {eventName: eventName})
}
