import React, { useState } from "react";
import { Tabbar } from '@telegram-apps/telegram-ui';
import '@telegram-apps/telegram-ui/dist/styles.css';
import { FaListUl, FaCog } from 'react-icons/fa'; // Импорт всех иконок из Font Awesome
import DialogListScreen from './DialogListScreen.js'
import SettingsScreen from './SettingsScreen.js'
import { useTranslation } from 'react-i18next';


const AppScreen = ({ userData, initDataRaw, initData, loaded }) => {
  const [hideTabBar, setHideTabBar] = useState(false);
  const { t } = useTranslation();

  const tabs = [
    {id: 0, text: t('dialogs'), icon: <FaListUl size={28}/>, component: <DialogListScreen userData={userData} initDataRaw={initDataRaw} hideTabBarHandler={setHideTabBar} />},
    {id: 1, text: t('settings'), icon: <FaCog size={28}/>, component: <SettingsScreen userData={userData} initDataRaw={initDataRaw} initData={initData} />}
  ];

  const [currentTab, setCurrentTab] = useState(tabs[0].id);

  return (
    <>
      {tabs[currentTab].component}
      {!hideTabBar?(
        <Tabbar style={{backgroundColor: 'var(--tgui--surface_primary)', zIndex: '9990'}}>
        {tabs.map(({
          id,
          text,
          icon
        }) => <Tabbar.Item disabled={!loaded} key={id} text={text} selected={id === currentTab} onClick={() => setCurrentTab(id)}>
        {icon}
        </Tabbar.Item>)}
        </Tabbar>
      ):(
        <>
          {/*empty when specific dialog opened*/}
        </>
      )}

    </>
  )
};

export default AppScreen;
