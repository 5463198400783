import React, { useState, useEffect, useRef } from "react";
import { Cell, FixedLayout, SegmentedControl, Spinner, Button, Blockquote, TabsList, InlineButtons } from '@telegram-apps/telegram-ui';
import { analyticsAdd, fetchBackend } from '../../api';
import { postEvent, subscribe, unsubscribe } from '@telegram-apps/sdk';
import { FaListUl, FaCog, FaArrowDown } from 'react-icons/fa';
import { FaCircleInfo, FaGears, FaStar, FaRetweet, FaCommentDots, FaPersonCircleQuestion } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';
import MDEditor from "@uiw/react-md-editor";

const AnswerControl = ({ initDataRaw, userData, currentDialog, scrollToBottom, setBigLoader, setBigLoaderText }) => {
  const [ selectedAnswerIndex, setSelectedAnswerIndex ] = useState(0);
  const [ generatedAnswers, setGeneratedAnswers ] = useState([{status: 'pending', answer: ''}, {status: 'pending', answer: ''}, {status: 'pending', answer: ''}]);
  const [ answerIsSending, setAnswerIsSending ] = useState(false);
  const [ initialGeneration, setInitialGeneration ] = useState(false);
  const [ controlTab, setControlTab ] = useState(0);
  const [ markdownValue, setMarkdownValue] = useState("**!**");
  const { t } = useTranslation();
  const generatedAnswersRef = useRef(generatedAnswers);
  const selectedAnswerIndexRef = useRef(selectedAnswerIndex);

  useEffect(() => {
    selectedAnswerIndexRef.current = selectedAnswerIndex;
  }, [selectedAnswerIndex]);
  // Обновляем реф, когда generatedAnswers изменяется
  useEffect(() => {
    generatedAnswersRef.current = generatedAnswers;
  }, [generatedAnswers]);

  useEffect(() => {
    if(!initialGeneration && currentDialog.id){
      setInitialGeneration(true)
      generateFewAnswers(currentDialog.id);
    }
    if(currentDialog.id){
      updateDescription();
    }
  }, [])

  useEffect(() => {
    proceedMainButton()

    const MainButtonListener = (event) => {
      if(event[0] === 'main_button_pressed'){
        if(controlTab === 0){
          sendSelected();
        }else if(controlTab === 1){
          setBigLoader(true);
          setTimeout(() => {
            setBigLoaderText(true);
          }, 3000)
          fetchBackend(initDataRaw, '/generateDescription', 'POST', { chatid: currentDialog.id, regeneratePlease: true }).then(answ => {
            if(answ.status === 'ok'){
              updateDescription();
              setBigLoader(false);
              analyticsAdd('Generated description', initDataRaw)
            }else{
              if(answ.error === 'endLimit'){
                setBigLoader(false);
                alert(t('unfortunatelyyourlimitisgone'));
              }else{
                alert(t('unexpectederror'))
              }
            }
          })
        }
      }
    };
    subscribe(MainButtonListener);
    // Удаляем слушатель при размонтировании компонента
    return () => {
      postEvent('web_app_setup_main_button', {is_visible: false})
      unsubscribe(MainButtonListener);
    };
  },[controlTab])

  useEffect(() => {
    proceedMainButton()
  }, [answerIsSending, generatedAnswers, controlTab])

  const proceedMainButton = () => {
    const answers = generatedAnswersRef.current;
    if(controlTab === 0){
      if(answers[selectedAnswerIndex].status === 'pending' || answerIsSending){
        postEvent('web_app_setup_main_button', {is_visible: true, is_active: false, is_progress_visible: true, text: t('loading')})
      }else{
        postEvent('web_app_setup_main_button', {is_visible: true, is_active: true, is_progress_visible: false, text: t('send')})
      }
    }else if(controlTab === 1){
        postEvent('web_app_setup_main_button', {is_visible: true, is_active: true, is_progress_visible: false, text: t('reanalyze')})
    }
  };

  async function updateDescription(){
    fetchBackend(initDataRaw, '/fetchSpecificDescription', 'POST', { chatid: currentDialog.id }).then((r) => {
      if(r.description !== 'nodescription'){
        r.description = r.description.replaceAll('\n\n\n', '\n\n')
        r.description = r.description.replaceAll('\n\n', '\n')
        setMarkdownValue(r.description);
      }else{
        setMarkdownValue(t('nodialogdescription'));
      }
    });
  }

  // generate 3 answers when opening dialog
  async function generateFewAnswers(useridforwhogenerating){
      const promises = [0, 1, 2].map(async (i) => {
        const answ = await fetchBackend(initDataRaw, '/generateAnswer', 'POST', { chatid: useridforwhogenerating });
        return { index: i, answ };
      });

      try {
        const results = await Promise.all(promises);
        // Проверяем, есть ли хотя бы один ответ с "endLimit"
        const limitReached = results.some(({ answ }) => answ?.error === 'endLimit');
        if (limitReached) {
          alert(t('unfortunatelyyourlimitisgone'));
          return; // Завершаем выполнение
        }
        setGeneratedAnswers((prev) => {
          const updatedAnswers = [...prev];
          results.forEach(({ index, answ }) => {
            updatedAnswers[index] = answ;
          });
          return updatedAnswers;
        });
      } catch (error) {
      }
  }

  // 'regenerate' button
  async function generateOneAnswer(useridforwhogenerating, idwheretoplace){
      const answ = await fetchBackend(initDataRaw, '/generateAnswer', 'POST', { chatid: useridforwhogenerating });

      try {
        if (answ.error !== undefined && answ.error === 'endLimit') {
          alert(t('unfortunatelyyourlimitisgone'));
          return; // Завершаем выполнение
        }

        setGeneratedAnswers((prev) => {
          const updatedAnswers = [...prev];
          updatedAnswers[idwheretoplace] = answ;
          return updatedAnswers;
        });
      } catch (error) {
      }
  }

  // send selected answer
  const sendSelected = () => {
    const answers = generatedAnswersRef.current;
    const currentIndex = selectedAnswerIndexRef.current;
    setAnswerIsSending(true)
    analyticsAdd('Clicked send answer', initDataRaw)
    fetchBackend(initDataRaw, '/sendAnswer', 'POST', { chatid: currentDialog.id, answer: answers[currentIndex].answer}).then(r =>{
      analyticsAdd('Sent answer', initDataRaw)
      if(r.status === 'ok'){
        setAnswerIsSending(false);
        setGeneratedAnswers((prev) => {
          const updatedAnswers = [...prev];
          updatedAnswers[currentIndex] = {status: 'pending', answer: ''};
          return updatedAnswers;
        });
        generateOneAnswer(currentDialog.id, currentIndex);
        // setGeneratedAnswers([{status: 'pending', answer: ''}, {status: 'pending', answer: ''}, {status: 'pending', answer: ''}]);
      }
    })
  };

  // send selected answer
  const sendSelectedPart = (m) => {
    setAnswerIsSending(true)
    analyticsAdd('Clicked send part answer', initDataRaw)
    if(m.slice(-1) === ',') m = m.slice(0,-1)  // change ',' to ''
    fetchBackend(initDataRaw, '/sendAnswer', 'POST', { chatid: currentDialog.id, answer: m}).then(r =>{
      analyticsAdd('Sent answer', initDataRaw)
      if(r.status === 'ok'){
        setAnswerIsSending(false);
        let nowAnswers = generatedAnswers;
        nowAnswers[selectedAnswerIndex].answer = nowAnswers[selectedAnswerIndex].answer.replace(m, '')
        setGeneratedAnswers(nowAnswers)
        // setGeneratedAnswers([{status: 'pending', answer: ''}, {status: 'pending', answer: ''}, {status: 'pending', answer: ''}]);
        // generateOneAnswer(CU.id, selectedAnswerIndex);
      }
    })
  };

  const formatGeneratedAnswer = (answer, selectedAnswerIndex) => {
    // Разбиваем строку на массив строк
    const newlineRegex = /\n{1,5}/;
    const spaceRegex = / {4,5}/;
    const sentenceRegex = /([.!])\s+/;

    let spltd = answer
      .split(newlineRegex) // Разбиваем по переносам строк
      .flatMap(item =>
        item.split(spaceRegex) // Разбиваем по 3-5 пробелам
          .flatMap(subItem =>
            subItem.split(sentenceRegex).map(part => part.trim()) // Разбиваем по знакам препинания
          )
      )
      .filter(item => item.length > 0) // Удаляем пустые строки
      .map(item => item === '.' ? '' : item) // Убираем точки
      .filter(item => item.length > 0) // Фильтруем пустые строки еще раз
      .map(item => item.replace(/\s{2,}/g, ' ')); // Заменяем множественные пробелы на одинарные
    spltd = spltd.map((item, index) => index === answer.length - 1 && Math.random() < 0.8 && item.endsWith('.') ? item.slice(0, -1) : item);

    if(spltd.length == 0){
      setGeneratedAnswers((prev) => {
        const updatedAnswers = [...prev];
        updatedAnswers[selectedAnswerIndex] = {status: 'pending', answer: ''};
        return updatedAnswers;
      });
      generateOneAnswer(currentDialog.id, selectedAnswerIndex);
    }

    // Преобразуем каждую строку в JSX
    return spltd.map((m, i) => (
      <React.Fragment key={i}>
        <Blockquote topRightIcon disabled={answerIsSending} style={answerIsSending?{backgroundColor: 'rgb(222,222,222)', marginBottom: '5px', padding: '2px', paddingRight: '20px', wordBreak: 'break-word'}:{marginBottom: '5px', padding: '2px', paddingRight: '20px', wordBreak: 'break-word'}} onClick={() => {if(!answerIsSending) sendSelectedPart(m, selectedAnswerIndex)}} type="text">
        {m}
        </Blockquote>
      </React.Fragment>
    ));
  };

  return (
    <Cell>
      <FixedLayout vertical='bottom' style={{backgroundColor: 'var(--tgui--tertiary_bg_color)', height: '50%'}}>
        <InlineButtons style={{width: '100%', marginTop: '-15%'}} mode="plain">
          <Cell style={{width: '100%'}} before={
            <InlineButtons.Item onClick={() => {
                    setGeneratedAnswers((prev) => {
                      const updatedAnswers = [...prev];
                      updatedAnswers[selectedAnswerIndex] = {status: 'pending', answer: ''};
                      return updatedAnswers;
                    });
                    generateOneAnswer(currentDialog.id, selectedAnswerIndex);
                    analyticsAdd('Regenerate ' + selectedAnswerIndex, initDataRaw)
                  }
            }>
              <FaRetweet size='20' />
            </InlineButtons.Item>
          } after={
            <InlineButtons.Item onClick={scrollToBottom}>
              <FaArrowDown size='20' />
            </InlineButtons.Item>
          }>
          </Cell>
        </InlineButtons>
        <div>

            {!userData.userTariffs.some(tariff => tariff.tariff_id === 1)?(
              <div>
              <TabsList style={{gap: '0px', paddingTop: '-5px', display: 'flex'}}>
                <TabsList.Item
                selected={controlTab===0?true:false}
                onClick={() => setControlTab(0)}
                >
                  <FaCommentDots/>
                </TabsList.Item>
                  <TabsList.Item
                  selected={controlTab===1?true:false}
                  onClick={() => setControlTab(1)}
                  disabled={!userData.userTariffs.some(tariff => tariff.product_name === 'dialog_description')}
                  >
                    <FaPersonCircleQuestion/>
                  </TabsList.Item>
              {/*
                <TabsList.Item>
                Настройки
                </TabsList.Item>
              */}
              </TabsList>
            </div>
          ):(
            <></>
          )}

          {controlTab === 0?(
            <>
              <div style={!userData.userTariffs.some(tariff => tariff.tariff_id === 1)?{whiteSpace: 'normal', wordBreak: 'break-word', margin: '12px', maxHeight: '220px', overflowY: 'auto'}:{whiteSpace: 'normal', wordBreak: 'break-word', margin: '12px', maxHeight: '250px', overflowY: 'auto'}}>
              {generatedAnswers[selectedAnswerIndex] === undefined || generatedAnswers[selectedAnswerIndex].status === 'pending'?<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '90vw'}}><Spinner size="l" /></div>:formatGeneratedAnswer(generatedAnswers[selectedAnswerIndex].answer, selectedAnswerIndex)}
              </div>
              <FixedLayout vertical='bottom'>
              <SegmentedControl>
              {[0, 1, 2].map((v, i) => {
                return (
                  <SegmentedControl.Item
                  key={i}
                  onClick={() => {setSelectedAnswerIndex(v);if(generatedAnswers[selectedAnswerIndex].status === 'pending') generateOneAnswer(currentDialog.id, selectedAnswerIndex);}}
                  selected={selectedAnswerIndex === v}
                  >
                  {t('variant')} {v+1}
                  </SegmentedControl.Item>
                );
              })}
              </SegmentedControl>
              </FixedLayout>
            </>
          ):(
            <></>
          )}

          {controlTab === 1?(
            <>
              <style>
                {`
                  .markdown-container {
                    word-wrap: break-word; /* Перенос слов, если строка слишком длинная */
                    white-space: pre-wrap; /* Сохраняет переносы строк и позволяет перенос длинных строк */
                    overflow-wrap: anywhere; /* Перенос даже длинных непрерывных слов */
                  }
                `}
              </style>
              <div className="markdown-container">
                <MDEditor.Markdown style={{ height: '41vh', maxWidth: "100%", overflow: "auto", padding: '10px' }} source={markdownValue} />
              </div>
            </>
          ):(
            <></>
          )}


        </div>
      </FixedLayout>
    </Cell>
  );
};

export default AnswerControl;
