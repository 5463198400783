import React, { useState } from "react";
import DialogList from './DialogList.js'
import Dialog from './Dialog.js'

const DialogListScreen = ({ userData, initDataRaw, hideTabBarHandler }) => {

  const [ showSpecificDialog, setShowSpecificDialog ] = useState(false);
  const [ dialogWhichWeAreShowingNow, setDialogWhichWeAreShowingNow ] = useState(0);

  return (
    showSpecificDialog ? (
      <Dialog userData={userData} initDataRaw={initDataRaw} dialogIndex={dialogWhichWeAreShowingNow} hideTabBarHandler={hideTabBarHandler} showSpecificDialogHandler={setShowSpecificDialog}  />
    ):(
      <DialogList userData={userData} initDataRaw={initDataRaw} showSpecificDialogHandler={setShowSpecificDialog} setDialogWhichWeAreShowingNowHandler={setDialogWhichWeAreShowingNow} />
    )
  );
};

export default DialogListScreen;
