import React from "react";
import { useTranslation } from 'react-i18next';


const MessagesList = ({ messages, currentDialog }) => {
  const { t } = useTranslation();
  return (
    <div className='messages'>
      {messages && messages.map((m, i) => {
        return (
          <div key={i} className={m.from===false?'message message-from':'message message-to'}>
            <p className="message-content">{m.message}</p>
            <div className={m.from===false?'message-timestamp-left':'message-timestamp-right'}>{m.from===false?currentDialog.realName:t('you')}</div>
          </div>
        )
      })}
    </div>
  );
};

export default MessagesList;
