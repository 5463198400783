import React, { useState, useEffect } from "react";
import { Cell, Section, List, Button, Banner, IconContainer, Avatar } from '@telegram-apps/telegram-ui';
import { fetchBackend, backendurl, analyticsAdd } from '../api';
import { useTranslation } from 'react-i18next';

import Lottie from "lottie-react";
//import lottieOnline from "../assets/lottieOnline.json";
import lottieLoader from "../assets/lottieLoader.json";



const DialogList = ({ userData, initDataRaw, showSpecificDialogHandler, setDialogWhichWeAreShowingNowHandler }) => {

  const [ chatList, setChatList ] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    setChatList(userData.chatlist);
  }, [userData]);

  // chat list updater
  useEffect(() => {
    const dialogListUpdater = setInterval(() => {
      fetchBackend(initDataRaw, '/dashboard', 'GET').then(r => {
        if(r.error !== undefined){
          switch (r.error) {
            case 'authbroken':
            analyticsAdd('Auth broken')
            alert(t('authbroken'))
            // eslint-disable-next-line no-restricted-globals
            window.location.reload();
            break;
            default:
            alert(t('authbroken'))
            // eslint-disable-next-line no-restricted-globals
            window.location.reload();
            return;
          }
        }
        setChatList(r.chatlist)
      })
    }, 8000)
    // Удаляем слушатель при размонтировании компонента
    return () => {
      clearInterval(dialogListUpdater);
    };
  }, [chatList]);


  return (
    <>
      <List>
        <Section>
        {chatList && chatList.length > 0 ?(chatList.map((cellText, index) => {
          let whatToShow;

           if(cellText[3] === 'acronym'){
             whatToShow = { acronym: cellText.realName.substr(0, 2) };
           }else{
             whatToShow = { src: backendurl+'/photo/'+cellText.username};
           }

           return (
             <Cell
               key={index}
               before={<Avatar size={48} {...whatToShow} />}
               subtitle={cellText.lastMessage !== null ? cellText.lastMessage : '...'}
               onClick={() => {setDialogWhichWeAreShowingNowHandler(index);showSpecificDialogHandler(true);}}
             >
               {cellText.realName}
             </Cell>
           );
         })):(
           <div style={{
               display: 'flex',
               flexDirection: 'column',
               justifyContent: 'center',
               alignItems: 'center',
               height: '100vh',
           }}>
               <Lottie animationData={lottieLoader} style={{ height: 120, width: 120 }} loop={true} />
           </div>
         )}
         <br/>
         <br/>
         <br/>
         <br/>
        </Section>
      </List>
    </>
  );
};

export default DialogList;
