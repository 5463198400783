import React, { useState, useEffect } from "react";
import { backButton } from '@telegram-apps/sdk';
import '../css/messages.css';
import { fetchBackend, analyticsAdd } from '../api';
import MessagesList from './DialogComponents/MessagesList.js';
import DialogHeader from './DialogComponents/DialogHeader.js';
import AnswerControl from './DialogComponents/AnswerControl.js';
import { useTranslation } from 'react-i18next';

import Lottie from "lottie-react";
//import lottieOnline from "../assets/lottieOnline.json";
import lottieLoader from "../assets/lottieLoader.json";

const Dialog = ({ userData, initDataRaw, dialogIndex, hideTabBarHandler, showSpecificDialogHandler }) => {

  const [ bigLoader, setBigLoader ] = useState(false);
  const [ bigLoaderText, setBigLoaderText ] = useState(false);
  const [ currentDialog, setCurrentDialog ] = useState([]);
  const [ messages, setMessages ] = useState([]);
  const { t } = useTranslation();


  backButton.mount();

  // current dialog setter
  useEffect(() => {
    setCurrentDialog(userData.chatlist[dialogIndex]);
  }, [dialogIndex]);

  // back button handler
  useEffect(() => {
    hideTabBarHandler(true)

    backButton.show()
    backButton.onClick(() => {
      backButton.hide()
      hideTabBarHandler(false)
      showSpecificDialogHandler(false)
    });
  }, [])

  // fetch messages, generate first few answers, dialog updater
  useEffect(() => {
    if(currentDialog.length == 0) return;

    analyticsAdd('Opens dialog', initDataRaw)
    setBigLoader(true);
    setTimeout(() => {
      setBigLoaderText(true);
    }, 3000)
    fetchBackend(initDataRaw, '/generateDescription', 'POST', { chatid: currentDialog.id }).then(answ => {


      if(answ.status === 'ok'){
        setBigLoader(false);
        analyticsAdd('Generated description', initDataRaw)
      }else{
        if(answ.error === 'endLimit'){
          setBigLoader(false);
        }else{
          alert(t('unexpectederror'))
        }
      }

      fetchBackend(initDataRaw, '/getChatData', 'POST', {chatid: currentDialog.id}).then(r => {
        setMessages(r.messages)
      });
      setTimeout(() => {
        scrollToBottom();
      }, 1500);


    })

  }, [currentDialog]);

  // messages updater
  useEffect(() => {
    const dialogUpdater = setInterval(() => {
      fetchBackend(initDataRaw, '/getChatData', 'POST', {chatid: currentDialog.id}).then(r => {
        if(JSON.stringify(messages) !== JSON.stringify(r.messages)){
          setMessages(r.messages)
        }
      })
    }, 5000)
    // Удаляем слушатель при размонтировании компонента
    return () => {
      clearInterval(dialogUpdater);
    };
  }, [messages])


  // scroll to button
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight, // Прокрутить до конца страницы
      behavior: 'smooth', // Гладкая прокрутка
    });
  }

  return (
    !bigLoader?(
      <div>
        <DialogHeader currentDialog={currentDialog} />
        <br/>
        <MessagesList
          messages={messages}
          currentDialog={currentDialog}
        />
        <br/>
        <AnswerControl
          initDataRaw={initDataRaw}
          userData={userData}
          currentDialog={currentDialog}
          scrollToBottom={scrollToBottom}
          setBigLoader={setBigLoader}
          setBigLoaderText={setBigLoaderText}
        />
      </div>
    ):(
      <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
      }}>
          <Lottie animationData={lottieLoader} style={{ height: 120, width: 120 }} loop={true} />
          {bigLoaderText && (
              <p style={{
                  marginTop: 20,
                  fontSize: '16px',
                  color: '#555',
                  textAlign: 'center',
                  animation: 'fadeIn 1s ease-in-out'
              }}>
                {t('analyzingyourdialog1')}
                <br/>
                {t('analyzingyourdialog2')}
                <br/>
                {t('analyzingyourdialog3')}
              </p>
          )}
          <style>
             {`
             @keyframes fadeIn {
                 from {
                     opacity: 0;
                 }
                 to {
                     opacity: 1;
                 }
             }
             `}
         </style>
      </div>
    )
  );
};

export default Dialog;
