import React, { useEffect, useState } from "react";
import { retrieveLaunchParams, init, backButton, postEvent } from '@telegram-apps/sdk';
import { fetchBackend, analyticsAdd } from '../api';
import AuthScreen from './AuthScreen.js'
import AppScreen from './AppScreen.js'
import '../i18n'; // Импорт конфигурации
import i18n from '../i18n'; // Импорт экземпляра i18n
import { useTranslation } from 'react-i18next';
const AppInit = () => {
  const { initDataRaw, initData } = retrieveLaunchParams();
  const [ authStatus, setAuthStatus ] = useState(true);
  const [ userData, setUserData ] = useState({});
  const [ loaded, setLoaded ] = useState(false);
  const { t } = useTranslation();

  window.TelegramGameProxy = { receiveEvent() {} };
  init();
  backButton.mount();

  useEffect(() => {
    i18n.changeLanguage(initData.user.languageCode);
    backButton.hide()
    postEvent('web_app_setup_main_button', {is_visible: false})
    analyticsAdd('Initialize app', initDataRaw)
    fetchBackend(initDataRaw, '/dashboard', 'GET').then(r => {
      if(r.error !== undefined){
        switch (r.error) {
          case 'authbroken':
            analyticsAdd('Auth broken')
            alert(t('accountbroken'))
            // eslint-disable-next-line no-restricted-globals
            window.location.reload();
            break;
          default:
          alert(t('accountbroken'))
            // eslint-disable-next-line no-restricted-globals
            window.location.reload();
            return;
        }
      }
      setAuthStatus(r.registered === 1);
      setLoaded(true)
      setUserData(r);
    })
  }, []);

  return (
    authStatus?(
      <AppScreen userData={userData} initDataRaw={initDataRaw} initData={initData} loaded={loaded} />
    ):(
      <AuthScreen initDataRaw={initDataRaw} />
    )
  );
};

export default AppInit;
