import React, { useState } from "react";
import { Cell, Title, Input, Divider, Button } from '@telegram-apps/telegram-ui';
import '@telegram-apps/telegram-ui/dist/styles.css';
import { fetchBackend, analyticsAdd } from '../api';
import { useTranslation } from 'react-i18next';

const AuthScreen = ({ initDataRaw }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [sentCode, setSentCode] = useState(false);
  const [smsCode, setSmsCode] = useState('');
  const [smsCodeApproved, setSmsCodeApproved] = useState(false);
  const [dfaNeeded, setDfaNeeded] = useState(false);
  const [dfaPass, setDfaPass] = useState('');
  const { t } = useTranslation();

  // send sms, first step of authorization
  const sendCode = () => {
    setSentCode(true);
    fetchBackend(initDataRaw, '/auth', 'POST', {phoneNumber: phoneNumber}).then(r => {
      analyticsAdd('Sent phone number', initDataRaw)
      if(r.status !== 'okWaitForCode'){
        alert(t('errorwithauth'))
      }
    })
  }

  // user entered sms code
  const hereIsMyCode = () => {
    fetchBackend(initDataRaw, '/authcode', 'POST', {phoneNumber: phoneNumber, code: smsCode}).then(r => {
      analyticsAdd('Sent code from sms', initDataRaw)
      if(r.status === 'gotit'){
        setSmsCodeApproved(true);
      }else if(r.status === 'need2fa'){
        setSmsCodeApproved(true);
        setDfaNeeded(true);
      }else if(r.status === 'authexpired'){
       alert(t('sessionexpired'))
      }
    })
  }

  // user entered dfa password
  const hereIsMyDfa = () => {
    fetchBackend(initDataRaw, '/authpass', 'POST', {phoneNumber: phoneNumber, pass2fa: dfaPass}).then(r => {
      analyticsAdd('Sent dfa password', initDataRaw)
      if(r.status === 'gotit'){
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }else if(r.status === 'authexpired'){
        alert(t('sessionexpired'))
     }else{
       alert(t('unexpectederror'))
     }
    })
  }


  return (
    <div>
      <Cell multiline='true' description={t('whyauthdescription')}>
        <Title level="2" weight="3">
        {t('newuserhello')}
        </Title>
      </Cell>
      <Input header={t('cellphonenumber')} placeholder="+799999999" onChange={e => setPhoneNumber(e.target.value)} />
      <Cell after={sentCode ? t('codesent') : <Button mode="filled" size="m" onClick={sendCode}>{t('sendcode')}</Button>}>
      </Cell>
      <br/>
      <Divider style={{borderWidth: '5px'}} />

      {/*sms code from TG*/}
      {sentCode?(
        smsCodeApproved?(
          <div>
            <Input header={t('pleasecodefromsms')} placeholder="342857" value={smsCode} disabled/>
            <Cell after={t('done')}>
            </Cell>
          </div>
        ):(
          <div>
            <Input header={t('pleasecodefromsms')} placeholder="342857" value={smsCode} onChange={e => setSmsCode(e.target.value)}/>
            <Cell after={<Button mode="filled" size="m" onClick={hereIsMyCode}>{t('accept')}</Button>}>
            </Cell>
          </div>
        )
      ):(
        <>
          {/*code not sent yet*/}
        </>
      )}


      {/*cloud password from TG*/}
      {sentCode?(
        dfaNeeded?(
          <div>
            <Input header={t('cloudpasswordfromtg')} placeholder="password" value={dfaPass} onChange={e => setDfaPass(e.target.value)}/>
            <Cell after={<Button mode="filled" size="m" onClick={hereIsMyDfa}>{t('accept')}</Button>}>
            </Cell>
          </div>
        ):(
          <>
            {/*cloud password is not needed*/}
          </>
        )
      ):(
        <>
          {/*code not sent yet*/}
        </>
      )}


    </div>
  );
};

export default AuthScreen;
