import React from "react";
import { Cell, Title } from '@telegram-apps/telegram-ui';
import { useTranslation } from 'react-i18next';

const DialogHeader = ({ currentDialog }) => {
  const { t } = useTranslation();
  return (
    <Cell multiline='true' hovered='true' description={t('answerwithai')}>
      <Title level="2" weight="3">
        {currentDialog.realName}
      </Title>
    </Cell>
  );
};

export default DialogHeader;
